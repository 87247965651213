.nf-field-element {
	.date-and-time {
		margin-bottom: 5px;
	}
	.hour.extra {
		padding: 5px;
	}
	.minute.extra {
		padding: 5px;
	}
	.ampm.extra {
		padding: 5px;
	}
}